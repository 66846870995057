import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class FeedbackReportService {
  @Inject(HttpService) private http!: HttpService;
  //添加
  public articleAdd(param: Dict<any>): Promise<any> { //wecom-buddy-backend/api/article/admin/queryArticleList
    return this.http.post('/wecom-buddy-backend/api/article/admin/addArticle', param);
  }
  //编辑
  public articleUpdate(param: Dict<any>): Promise<any> { //wecom-buddy-backend/api/article/admin/queryArticleList
    return this.http.post('/wecom-buddy-backend/api/article/admin/articleUpdate', param);
  }
  //查询
  public queryArticleList(param: Dict<any>): Promise<any> {
    return  this.http.post('/wecom-buddy-backend/api/article/admin/queryArticleList', param);
  }
  //详情
  public queryArticleInfo(id: Dict<any>): Promise<any> {
    return  this.http.get(`/wecom-buddy-backend/api/article/admin/detail/${id}`);
  }
  //删除
  public queryArticleDelete(param: Dict<any>): Promise<any> {
    return  this.http.post('/wecom-buddy-backend/api/article/admin/deleteArticleById', param);
  }
  //删除
  public updateArticleById(param: Dict<any>): Promise<any> {
    return  this.http.post('/wecom-buddy-backend/api/article/admin/updateArticleById', param);
  }
  //根据所属类型获取应用/业务场景名称
  public queryAppOrScenarioByType(param: Dict<any>): Promise<any> {
    return  this.http.post('/wecom-buddy-backend/api/article/admin/queryAppOrScenarioByType', param);
  }
  //根据所属类型获取应用/业务场景名称
  public querySecondMenuByAppOrScenarioId(param: Dict<any>): Promise<any> {
    return  this.http.post('/wecom-buddy-backend/api/article/admin/querySecondMenuByAppOrScenarioId', param);
  }

}
