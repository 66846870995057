import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class BasicInformationService {
  @Inject(HttpService) private http!: HttpService;
  // 获取状态
  public getAssessmentStatus(param?: Dict<any>): Promise<any> {
    return this.http.get('/wecom-skill-backend/admin/assessment/status', param);
  }
  // 添加
  public createAssessment(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/create',
      param
    );
  }
  // 编辑
  public updateAssessment(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/update',
      param
    );
  }
  // 查询
  public getAssessmentList(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/listPage',
      param
    );
  }
  // 详情
  public getDetailById(id: string | number): Promise<any> {
    return this.http.get(`/wecom-skill-backend/admin/assessment/${id}`);
  }
  // 预览
  public previewAssessment(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/preview', param);
  }
  // 发布
  public releaseAssessment(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/release', param);
  }
  // TODO 删除
  public removeAssessmentById(id: string | number): Promise<any> {
    return this.http.post(`/wecom-skill-backend/admin/assessment/delete/${id}`);
  }
}
